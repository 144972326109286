import { useQueryString } from '@segunosoftware/equinox';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAccount } from '../hooks/useAccount';

const INTERCOM_ID = import.meta.env.VITE_INTERCOM_ID;

export const activateSupport = () => {
	if (window.Intercom) {
		window.Intercom('show');
		return;
	}
};

export const activateMessage = (subject: string, message: string = '') => {
	if (window.Intercom) {
		activateSupport();
		window.Intercom('showNewMessage', `${subject} ${message}`);
		return;
	}
};

export const openArticle = (articleId: string) => {
	if (window.Intercom) {
		window.Intercom('showArticle', articleId);
		return;
	}
};

const submitPageViewEvent = () => {
	if (window.Intercom) {
		window.Intercom('update');
	}
};

export default function SupportChat() {
	const account = useAccount();
	const location = useLocation();
	const { article } = useQueryString();
	const [scriptsLoaded, setScriptsLoaded] = useState(false);
	const [intercomBooted, setIntercomBooted] = useState(false);

	useEffect(() => {
		if (!scriptsLoaded) {
			// Intercom
			if (!window.Intercom) {
				window.Intercom = function () {
					// @ts-ignore
					window.Intercom.c(arguments);
				};
				// @ts-ignore
				window.Intercom.q = [];
				// @ts-ignore
				window.Intercom.c = function (args) {
					// @ts-ignore
					window.Intercom.q.push(args);
				};
				const scriptTag = document.createElement('script');
				scriptTag.type = 'text/javascript';
				scriptTag.async = true;
				scriptTag.src = `https://widget.intercom.io/widget/${INTERCOM_ID}`;
				document.body.appendChild(scriptTag);
			}

			setScriptsLoaded(true);
		}
	}, [scriptsLoaded]);

	useEffect(() => {
		if (!scriptsLoaded) {
			return;
		}
		return () => {
			// The double render from StrictMode breaks Intercom since the init/destroy is so fast
			if (import.meta.env.PROD && window.Intercom) {
				window.Intercom('shutdown');
			}
		};
	}, [scriptsLoaded]);

	useEffect(() => {
		if (scriptsLoaded && typeof article === 'string') {
			openArticle(article);
		}
	}, [scriptsLoaded, article]);

	useEffect(() => {
		if (!scriptsLoaded) {
			return;
		}
		submitPageViewEvent();
	}, [scriptsLoaded, location]);

	useEffect(() => {
		if (!scriptsLoaded) {
			return;
		}
		if (account && window.Intercom && !intercomBooted) {
			const companyId = account.shop.substring(0, account.shop.indexOf('.'));
			window.Intercom('boot', {
				api_base: 'https://api-iam.intercom.io',
				app_id: INTERCOM_ID,
				name: account.ownerName,
				email: account.ownerEmail,
				user_hash: account.supportUserHash,
				created_at: account.createdAt.getTime() / 1000,
				company: {
					id: companyId
				}
			});
			submitPageViewEvent();
			setIntercomBooted(true);
		}
	}, [scriptsLoaded, account, intercomBooted]);

	return null;
}
