import { LoadingScreen } from '@segunosoftware/equinox';
import { Navigate, useLocation } from 'react-router-dom';
import { useAccountFetch } from '../hooks/useAccount';

export type ProtectedRouteProps = {
	element: JSX.Element;
};

export default function ProtectedRoute({ element }: ProtectedRouteProps) {
	const { account } = useAccountFetch(true);
	const location = useLocation();

	if (!account) {
		return <LoadingScreen />;
	}

	const isWelcomePath = location.pathname.indexOf('/welcome') === 0;
	if (!account.accountSurvey && !isWelcomePath) {
		const queryParams = new URLSearchParams(location.search);
		const hasAuthParams = queryParams.has('user') && queryParams.has('state');
		queryParams.append('redirect', hasAuthParams ? '/canva-complete-auth' : location.pathname);
		return <Navigate to={`/welcome?${queryParams.toString()}`} />;
	}

	return element;
}
